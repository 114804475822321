export default [
    {
        key: 'company_type_name',
        sortable: false,
        label: 'Tipo Societario',
        visible: true,
    },
    {
        key: 'business_name',
        sortable: false,
        label: 'Nombre',
        visible: true,
    },
    {
        key: 'email',
        sortable: true,
        label: 'Correo Electronico',
        visible: true,
    },
    {
        key: 'display_name_as',
        sortable: true,
        label: 'Nombre C.',
        visible: true,
    },
    {
        key: 'main_number',
        sortable: true,
        label: 'Telefono',
        visible: true,
    },
    {
        key: 'ein_tax_id_ruc',
        sortable: true,
        label: 'RUC',
        visible: true,
    },
    {
        key: 'address',
        sortable: true,
        label: 'Direccion',
        visible: true,
    },
    {
        key: 'city',
        sortable: true,
        label: 'Distrito',
        visible: true,
    },
    {
        key: 'state',
        sortable: true,
        label: 'Ciudad',
        visible: true,
    },
    {
        key: 'created_by',
        sortable: true,
        label: 'Created by',
        visible: true,
    },
    {
        key: 'actions',
        sortable: false,
        label: 'Actions',
        visible: true,
    },

]
