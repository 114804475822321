<template>
  <div>
    <!-- ID 1 = companies -->
    <DynamicFormsConfiguration
      :id="1"
      :name="'Companies'"
      :moduleId="currentModule"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import DynamicFormsConfiguration from "@/views/management/views/settings/views/dynamic_forms/components/DynamicFormsConfiguration.vue";
export default {
  components: {
    vSelect,
    DynamicFormsConfiguration,
  },
  directives: {
    Ripple,
  },
  props: {},

  data() {
    return {};
  },
  computed: {
     currentModule() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.deleteCiaStyle:hover {
  background: #df3d44;
  color: #fff !important;
}

.dark-layout {
  .deleteCiaStyle:hover {
    background: #df3d44;
    color: #000 !important;
  }
}

.selectLogo {
  position: relative;
}

.message_logo {
  background: rgba($color: #000000, $alpha: 1);
  height: 50px;
  font-size: 20px;
  opacity: 0;
  position: absolute;
}

.selectLogo:hover {
  //opacity: 0.5;
  .message_logo {
    opacity: 1;
    bottom: 10px;
    padding: 10px 20px;
    width: 100%;
    color: #fff;
  }
}
</style>
