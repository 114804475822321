<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="primary"
        class="mr-1 mt-2 mb-2"
        :to="{ name: 'companies-old-management-us' }"
        ><tabler-icon
          class="mr-50 text-white"
          size="15"
          icon="BuildingIcon"
        />OLD COMPANIES
      </b-button>
      <b-button
        variant="info"
        class="mr-1 mt-2 mb-2"
        @click="openSidebarCompany(company, 1)"
        ><feather-icon
          icon="PlusIcon"
          size="15"
          class="mr-50 text-white"
        />TEMPLATE
      </b-button>
      <b-button
        variant="success"
        class="mr-1 mt-2 mb-2"
        @click="addUpdateCompany(company, 1)"
        ><feather-icon
          icon="PlusIcon"
          size="15"
          class="mr-50 text-white"
        />CREATE
      </b-button>
    </div>
    <b-card no-body class="mb-2">
      <filter-slot
        :custom-filtering="fields"
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="resetAllFilters"
        @reload="$refs['companiesList'].refresh()"
        @reloadTable="reloadFields"
      >
        <b-table
          id="companiesList"
          slot="table"
          ref="companiesList"
          class="m-1"
          sticky-header="70vh"
          no-provider-filtering
          :busy.sync="isBusy"
          :items="getCustomCompanyInfo"
          :fields="filteredFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          show-empty
          small
        >
          <!-- key-field="key" -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template
            v-for="field in filteredFields"
            v-slot:[`cell(${field.key})`]="{ item }"
          >
            <span v-if="field.custom_type_component_id === 15" :key="field.key">
              <b-dropdown
                variant="link"
                no-caret
                boundary="viewport"
              >
                <template #button-content>
                  <tabler-icon
                    size="20"
                    icon="FolderIcon"
                    v-b-tooltip.hover.top="'View Files'"
                    :class="getCustomInfo(item, field.key).value.length ? 'text-primary':'text-secondary'"
                  />
                </template>
                <b-dropdown-item
                  v-for="(data, index) in getCustomInfo(item, field.key).value"
                  :key="index"
                  :href="data.fileapproved"
                  target="_blank"
                  style="width: 200px"
                >
                  <span class="text-truncate elipsis">
                    <amg-icon
                      icon="FilePdfIcon"
                      v-b-tooltip.hover.bottom="data.custom_name"
                      style="height: 18px; width: 18px; margin-bottom: 3px"
                      class="cursor-pointer mt-0 mr-1 bigger text-danger text-center"
                    />
                    <span>{{data.custom_name}}</span>
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

            <span
              v-else-if="field.custom_type_component_id === 14"
              :key="field.key"
            >
              <div v-html="getCustomInfo(item, field.key).value"></div>
            </span>
            <span
              v-else-if="field.custom_type_component_id === 16"
              :key="field.key"
            >
              <a
                v-if="getCustomInfo(item, field.key).value"
                :href="getCustomInfo(item, field.key).value"
                target="_blank"
                v-b-tooltip.hover.top="'View URL'"
              ><tabler-icon
                size="20"
                icon="ExternalLinkIcon"
                class="text-primary"
              /></a>
              <tabler-icon
                v-else
                v-b-tooltip.hover.top="'View URL'"
                size="20"
                icon="ExternalLinkIcon"
                class="text-secondary"
              />
            </span>
            <span
              v-else-if="field.custom_type_component_id === 8"
              :key="field.key"
            >
              <span v-if="getCustomInfo(item, field.key).multiple">
                <b-dropdown variant="link" no-caret boundary="viewport">
                  <template #button-content>
                    <tabler-icon
                      size="20"
                      icon="CircleCheckIcon"
                      :class="getCustomInfo(item, field.key).value ? 'text-success':'text-secondary'"
                      v-b-tooltip.hover.top="'View Elections'"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="(option, index) in getCustomInfo(item, field.key).value"
                    :key="index"
                  >
                    <span>{{option}}</span>
                  </b-dropdown-item>
                </b-dropdown>
                <!-- <div
                  v-for="(option, index) in getCustomInfo(item, field.key)
                    .value"
                  :key="index"
                >
                  {{ option }}
                </div> -->
              </span>
              <span v-else>
                {{ getCustomInfo(item, field.key).value }}
              </span>
            </span>
            <span
              v-else-if="field.custom_type_component_id === 10"
              :key="field.key"
            >
              <b-dropdown variant="link" no-caret boundary="viewport">
                <template #button-content>
                  <tabler-icon
                    size="20"
                    icon="CheckboxIcon"
                    :class="getCustomInfo(item, field.key).value.length ? 'text-success':'text-secondary'"
                    v-b-tooltip.hover.top="'View Elections'"
                  />
                </template>
                <b-dropdown-item
                  v-for="(option, index) in getCustomInfo(item, field.key).value"
                  :key="index"
                >
                  <span>{{option}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span
              v-else-if="field.custom_type_component_id === 4"
              :key="field.key"
            >
              {{ getCustomInfo(item, field.key).value | myGlobal }}
            </span>
            <span
              v-else-if="field.custom_type_component_id === 11"
              :key="field.key"
            >
              {{ getCustomInfo(item, field.key).value | formatMoney }}
            </span>
            <span
              v-else-if="field.custom_type_component_id === 7"
              :key="field.key"
            >
              {{ isCeo ? getCustomInfo(item, field.key).value : '**********' }}
            </span>
            <span :key="field.key" v-else>
              {{ getCustomInfo(item, field.key).value }}
            </span>
          </template>
          <!-- <template #cell()="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              {{ data.item.custom_info }}
            </div>
          </template> -->
          <!-- <template v-slot:cell(category)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              {{ data.item.category }}
            </div>
          </template>

          <template v-slot:cell(created_by)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <p>{{ data.item.user_name }}</p>
              <p>{{ data.item.created_at | myGlobalDay }}</p>
            </div>
          </template> -->
          <template v-slot:cell(business_name)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <!-- <router-link
                target="_blank"
                :class="[textLink]"
                :to="`/management/companies/dashboard/${data.item.id}`"
              > -->
                  <!-- v-b-tooltip.hover -->
                <div
                  class="elipsis"
                  style="max-width: 250px; cursor: pointer;"
                  :class="[textLink]"
                  :title="`${data.item.business_name}`"
                  @click="validateRoleActionEdit(data.item, 2)"
                >
                  {{ data.item.business_name }}
                </div>
              <!-- </router-link> -->
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <div class="d-flex justify-content-center align-items-start">
              <feather-icon
                title="EDIT"
                icon="EditIcon"
                size="20"
                class="cursor-pointer text-warning"
                @click="validateRoleActionEdit(data.item, 2)"
              />
              <feather-icon
                title="DELETED"
                icon="Trash2Icon"
                size="20"
                class="cursor-pointer ml-1 text-danger"
                @click="openDeleteCompany(data.item)"
              />
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>

    <b-sidebar
      id="sidebar-right"
      v-model="sidebarState"
      :bg-variant="isDarkSkin ? 'dark' : 'white'"
      :title="titleSidebar + ' COMPANY'"
      right
      width="1300px"
    >
      <create-company
        v-if="sidebarState"
        :operation="operation"
        :company-prop="selectedCompany"
        @closeSidebar="closeSidebar"
        @refreshTable="resetSearch"
        ref="companyForm"
        :country="getCountry === 'USA' ? 'US' : 'PE'"
      />
      <template #footer>
        <b-card class="mb-0">
          <template #default>
            <div class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mr-1"
                variant="outline-secondary"
                @click="closeSidebar"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="saveCompany(operation)"
              >
                {{ operation === 1 ? "Save" : "Update" }}
              </b-button>
            </div>
          </template>
        </b-card>
      </template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      v-model="sidebarStateTemplate"
      :bg-variant="isDarkSkin ? 'dark' : 'white'"
      :title="titleTemplate"
      right
      width="100vw"
    >
      <div>
        <DynamicFormsConfiguration
          :id="1"
          :name="'Companies'"
          :moduleId="currentModule"
          :country="currentCountry"
          v-if="sidebarStateTemplate"
          :operation="operation"
          :company-prop="selectedCompany"
          @close="getCustomFields"
          @closeSidebar="closeSidebar"
          @refreshTable="resetSearch"
          ref="companyForm"
        />
      </div>

      <template #footer>
        <b-card class="mb-0">
          <template #default>
            <div class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mr-1"
                variant="outline-danger"
                @click="closeSidebar"
              >
                Close
              </b-button>
            </div>
          </template>
        </b-card>
      </template>
    </b-sidebar>

    <verify-ceo-password
      v-if="modalVerifyCeoPass"
      @close="modalVerifyCeoPass = false"
      @add-update-company="OpensidebarState()"
    />
  </div>
</template>

  <script>
import { mapGetters } from "vuex";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import dataFields from "@/views/management/views/companies/companies-module/fields.data";
import dataFieldsPe from "@/views/management/views/companies/companies-module/fields-pe.data";
import CompaniesService from "@/views/management/views/companies/companies.service";
import CreateCompany from "@/views/management/views/companies/companies-module/create-company/CreateCompany.vue";
import TemplateCompany from "@/views/management/views/companies/companies-module/create-company/TemplateCompany.vue";
import VerifyCeoPassword from "@/views/management/views/companies/companies-module/create-company/Modals/VerifyCeoPassword.vue";
import DynamicFormsConfiguration from "@/views/management/views/settings/views/dynamic_forms/components/DynamicFormsConfiguration.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    FilterSlot,
    CreateCompany,
    VerifyCeoPassword,
    TemplateCompany,
    DynamicFormsConfiguration,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [],
      totalRows: 0,
      sidebarState: false,
      sidebarStateTemplate: false,
      operation: null,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by name...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: [],
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      statusLead: [],
      company: {
        another_address: null,
        category: "BUSINESS",
        address: null,
        city: null,
        country: "US",
        direct_number: null,
        display_name_as: null,
        ein_tax_id: null,
        email: null,
        extension: null,
        mailling_address: null,
        mailling_city: null,
        mailling_state: null,
        mailling_zip_code: null,
        pob_city: null,
        pob_state: null,
        pob_zipcode: null,
        main_number: null,
        name: null,
        other_number: null,
        ruc: null,
        state: null,
        toll_free: null,
        use_main_address: null,
        zip_code: null,
        // Payroll
        cts: true,
        bonuses: true,
        payroll: true,
        regimen: null,
        // IMAGEN UPLOAD
        image: "",
        fileImg: "",
        extension2: "",
        company_type_id: null,
        po_box: null,
        fax: null,
        custom_form_id: null,
        custom_form_group: null,
        tax_regime_id: null,
      },
      titleSidebar: "",
      modalVerifyCeoPass: false,
    };
  },
  computed: {
    isCeo() {
      return this.currentUser.role_id == 1;
    },
    filteredFields() {
      return this.fields.filter((item) => item.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    getCountry() {
      return this.$route.name == "companies-management-us" ? "USA" : "PERU";
    },
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    currentCountry() {
      return this.$route.matched[3].meta.countryCode;
    },
    titleTemplate(){
      return `TEMPLATE COMPANY - ${this.getCountry}`
    }
  },
  async mounted() {
    await this.getCustomFields();
    // this.loadConfigurationFromLocalStorage();
  },
  methods: {
    // NUEVO
    getCustomInfo(item, key) {
      const customInfo = item.custom_info.find((info) => info.key === key);
      if (!customInfo) {
        return { value: "" };
      }
      return customInfo;
    },
    async getCustomFields() {
      try {
        const response = await CompaniesService.getCustomFields({
          country: this.getCountry === "USA" ? "US" : "PE",
        });
        this.fields = response.data.data;
        this.fields.unshift({
          key: "business_name",
          sortable: false,
          label: "Business Name",
          visible: true,
        });
        this.fields.push({
          key: "actions",
          label: "Actions",
          sortable: false,
          visible: true,
        });
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getCustomCompanyInfo(ctx) {
      try {

        const params = {
          _page: ctx.currentPage,
          _per_page: ctx.perPage,
          _country: this.getCountry === "USA" ? "US" : "PE",
          _name: this.filterPrincipal.model,
        };
        const {data} = await CompaniesService.getCustomCompanyInfo(params);
        this.items = data.data;
        this.startPage = data.from;
        this.toPage = data.to;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        return this.items;
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    setFieldsByCountry() {
      if (this.getCountry === "USA") {
        this.fields = dataFields;
      } else {
        this.fields = dataFieldsPe;
      }
    },
    saveConfigurationToLocalStorage() {
      const key =
        this.getCountry === "USA"
          ? "fieldsConfiguration"
          : "fieldsConfigurationPe";
      localStorage.setItem(key, JSON.stringify(this.fields));
    },
    loadConfigurationFromLocalStorage() {
      const key =
        this.getCountry === "USA"
          ? "fieldsConfiguration"
          : "fieldsConfigurationPe";
      const storedConfiguration = localStorage.getItem(key);
      if (storedConfiguration) {
        this.fields = JSON.parse(storedConfiguration);
      }
    },
    toEditCompany(data) {
      this.$router.push(`/management/companies/edit/${data.id}`);
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        // eslint-disable-next-line no-param-reassign
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.companiesList.refresh();
    },
    resetSearch() {
      this.searchInput = "";
      this.$refs.companiesList.refresh();
    },

    addUpdateCompany(company, operation) {
      this.selectedCompany = company;
      this.operation = operation;
      this.titleSidebar = operation === 1 ? "ADD" : "UPDATE";
      this.sidebarState = true;
    },
    OpenVerifyCeoPassword(company, operation) {
      this.selectedCompany = company;
      this.operation = operation;
      this.titleSidebar = operation === 1 ? "ADD" : "UPDATE";
      this.modalVerifyCeoPass = true;
    },
    validateRoleActionEdit(company, operation) {
      if (this.currentUser.role_id == 1) {
        this.addUpdateCompany(company, operation);
      } else {
        this.OpenVerifyCeoPassword(company, operation);
      }
    },
    OpensidebarState() {
      this.sidebarState = true;
    },
    saveCompany(operation) {
      if (operation === 1) {
        this.$refs.companyForm.createCompany();
      } else {
        this.$refs.companyForm.updateCompany();
      }
    },
    closeSidebar() {
      this.sidebarState = false;
      this.sidebarStateTemplate = false;
      this.selectedCompany = null;
    },

    async openDeleteCompany(data) {
      const params = {
        company_id: data.id,
        deleted_by: this.currentUser.user_id,
      };
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          await CompaniesService.deleteCompanies(params);

          this.$refs.companiesList.refresh();

          this.showSuccessSwal("Company has been deleted successfully");
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
      return [];
    },
    async search(ctx) {
      let order = ctx.sortDesc ? "asc" : "desc";
      let order_by = ctx.sortBy ? ctx.sortBy : "id";
      order = ctx.sortBy ? order : "desc";
      try {
        const params = {
          perpage: this.paginate.perPage,
          orderby: order_by,
          order: order,
          campo: this.filterPrincipal.model,
          country: this.getCountry === "USA" ? "US" : "PE",
        };
        const data = await CompaniesService.getCompanies(
          params,
          ctx.currentPage
        );

        this.items = data.data.data;
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;

        return this.items;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async reloadFields(ColumnsVisible) {
      this.fields = ColumnsVisible;
      const params = {
        fields: ColumnsVisible,
        country: this.getCountry === "USA" ? "US" : "PE",
      };
      await CompaniesService.updateCustomFieldVisibility(params);
      // this.saveConfigurationToLocalStorage();
    },
    openSidebarCompany(company, operation) {
      this.selectedCompany = company;
      this.operation = operation;
      this.titleSidebar = operation === 1 ? "ADD" : "UPDATE";
      this.sidebarStateTemplate = true;
    },
  },
};
</script>

  <style scoped>
.elipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
</style>
