export default [
    {
        key: 'company_type_name',
        sortable: false,
        label: 'Company Type',
        visible: true,
    },
    {
        key: 'business_name',
        sortable: false,
        label: 'Business Name',
        visible: true,
    },
    {
        key: 'email',
        sortable: true,
        label: 'Email',
        visible: true,
    },
    {
        key: 'display_name_as',
        sortable: true,
        label: 'Display Name As',
        visible: true,
    },
    {
        key: 'main_number',
        sortable: true,
        label: 'Main Number',
        visible: true,
    },
    {
        key: 'ein_tax_id_ruc',
        sortable: true,
        label: 'EIN',
        visible: true,
    },
    {
        key: 'address',
        sortable: true,
        label: 'Address',
        visible: true,
    },
    {
        key: 'city',
        sortable: true,
        label: 'City',
        visible: true,
    },
    {
        key: 'state',
        sortable: true,
        label: 'State',
        visible: true,
    },
    {
        key: 'zip_code',
        sortable: true,
        label: 'Zip Code',
        visible: true,
    },
    {
        key: 'created_by',
        sortable: true,
        label: 'Created by',
        visible: true,
    },
    {
        key: 'actions',
        sortable: false,
        label: 'Actions',
        visible: true,
    },

]
