<template>
  <div>
    <b-modal
      ref="modal-pass"
      centered
      modal-class="modal-primary"
      title-tag="h3"
      title="Verify CEO Password"
      hide-footer
      size="sm"
      @hide="hideModal"
    >
      <div>
        <ValidationObserver ref="modalPassCeo">
          <b-row class="my-1 mt-2 mb-1">
            <b-col sm="10" md="10" lg="10">
              <validation-provider
                name="passwordValidate"
                :rules="{ required: true, min: 3 }"
                v-slot="{ errors }"
              >
                <b-form-group>
                  <b-form-input
                    id="inputpassnew"
                    :type="typeInputPass"
                    v-model="pass"
                    class="w-100"
                    :class="errors[0]"
                  ></b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="2" md="2" lg="2" class="pl-0">
              <b-input-group-text
                role="button"
                @click="ViewPass()"
                class="bg-primary border-info"
              >
                <feather-icon
                  :icon="typeInputPass == 'password' ? 'EyeOffIcon' : 'EyeIcon'"
                  size="20"
                  role="button"
                  class="mx-auto text-light"
                />
              </b-input-group-text>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12" class="text-right">
              <b-button
                class="mb-2 ml-1"
                variant="gradient-primary"
                @click="verifyMethod()"
              >
                Verify
              </b-button>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CompaniesService from "@/views/management/views/companies/companies.service";

export default {
  data() {
    return {
      pass: "",
      typeInputPass: "password",
    };
  },
  mounted() {
    this.toggleModal("modal-pass");
  },
  methods: {
    hideModal() {
      this.$emit("close");
    },
    async verifyMethod() {
      const veeValidation = await this.$refs.modalPassCeo.validate();
      if (veeValidation) {
        const { data } = await CompaniesService.verifyCeoPassword({
          pass: this.pass,
        });
        if (data == "ok") {
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Correct Password"
          );
          this.$emit("add-update-company");
          this.removePreloader();
          this.hideModal();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Incorrect Password"
          );
          this.removePreloader();
        }
      }
    },
    ViewPass() {
      if (this.typeInputPass == "password") {
        this.typeInputPass = "text";
      } else {
        this.typeInputPass = "password";
      }
    },
  },
};
</script>
